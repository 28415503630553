import {
  Select,
  OptionBase,
  ChakraStylesConfig,
  chakraComponents,
  SelectComponentsConfig,
  GroupBase,
  MultiValue,
  SingleValue,
} from 'chakra-react-select';
import { FC, ReactNode } from 'react';

import { Button } from 'quotient';

// https://www.w3.org/WAI/ARIA/apg/patterns/combobox/

export type SelectOption = OptionBase & {
  value: string;
  label: ReactNode;
};

export type ComboboxProps = {
  options: SelectOption[];
} & {
  isMulti: boolean;
  value: MultiValue<SelectOption> | SingleValue<SelectOption>;
  onChange: (newValue: any, actionMeta: any) => void;
  dataTestId?: string;
  includeDropdownIndicator?: boolean;
};
const chakraStyles: ChakraStylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
  container: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'gray.300',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: 'none',
    boxShadow: 'none',
    padding: '2px',
    h: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    textStyle: 'body',
    color: 'gray.800',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'blue.500' : 'white',
    color: state.isSelected ? 'white' : 'gray.800',
    _hover: {
      backgroundColor: state.isSelected ? 'blue.600' : 'gray.100',
    },
    padding: '8px 12px',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'md',
    borderRadius: '4px',
    borderColor: 'gray.300',
    marginTop: '4px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '4px',
    borderColor: 'gray.300',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    width: 'auto',
    height: 'auto',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};
const components: SelectComponentsConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
  ClearIndicator: (props: any) => (
    <chakraComponents.ClearIndicator {...props}>
      <Button size="sm" unmask variant="ghost">
        Clear all
      </Button>
    </chakraComponents.ClearIndicator>
  ),
};
export const QuotientCombobox: FC<ComboboxProps> = ({
  options,
  value,
  onChange,
  isMulti,
  dataTestId,
  includeDropdownIndicator = false,
}) => {
  // modifying chakraStyles makes QuotientCombobox impure, so scoping styles
  // here to prevent impurities. If you need to modify a style dynamically, do it like this.
  const styles = { ...chakraStyles };
  if (includeDropdownIndicator) {
    styles.dropdownIndicator = (provided) => {
      return { ...provided, background: 'none' };
    };
  }
  return (
    <Select
      chakraStyles={styles}
      components={components as any}
      data-testid={dataTestId}
      isMulti={Boolean(isMulti)}
      options={options}
      value={value}
      onChange={onChange as any}
    />
  );
};
