import { useMutation, UseMutationOptions, UseQueryOptions, useQuery, useQueryClient } from 'react-query';

import { postRequest, getRequest, deleteRequest } from 'services/apiRequests';
import type { StringifiedUUID, APIList } from 'types';
import { APIError } from 'utils/errors';

const apiUrl = `/api/v1/collaboration/collaborators/`;

type DeletePayload = {
  ids: string[];
  source?: string;
};

const deleteSharedResources = (ids: string[], csrfToken: string) => {
  return deleteRequest<DeletePayload, null>(
    `/api/v1/users/permissions/shared-resources/bulk-delete/`,
    { ids },
    csrfToken,
  );
};

export enum OBJECT_TYPE {
  TERRITORY = 'territory',
}

export type CreateCollaboratorsRequest = {
  user_ids: StringifiedUUID[];
  object_id: StringifiedUUID;
  object_type: OBJECT_TYPE;
  role: string;
};

const createCollaborators = (csrfToken: string) => (payload: CreateCollaboratorsRequest) => {
  return postRequest<CreateCollaboratorsRequest, null>(apiUrl, payload, csrfToken);
};

const useCreateCollaboratorsMutation = (
  csrfToken: string,
  options: UseMutationOptions<null, APIError, CreateCollaboratorsRequest> = {},
) => {
  return {
    mutation: useMutation<null, APIError, CreateCollaboratorsRequest>(createCollaborators(csrfToken), options),
  };
};

export type GetCollaboratorsResponse = {
  resource: {
    object: 'shared_resource';
    id: StringifiedUUID;
    organization_id: StringifiedUUID;
    accessor_id: StringifiedUUID;
    accessor_type: 'user';
    resource_id: StringifiedUUID;
    resource_type: 'territory';
    resource_path: StringifiedUUID[];
    permission_type: 'read';
    created_at: StringifiedUUID;
    updated_at: StringifiedUUID;
  };
  ancestor_name: string | null;
  is_ancestor: boolean;
  accessor: {
    object: 'user';
    id: StringifiedUUID;
    first_name: string;
    last_name: string;
    email: string;
    is_organization_admin: boolean;
  };
  accessor_role: 'collaborator' | 'commenter';
};

const getCollaborators = (territoryId: StringifiedUUID) =>
  getRequest<null, APIList<GetCollaboratorsResponse>>(`${apiUrl}${territoryId}?resource_type=territory`);

const useGetCollaborators = (
  options: UseQueryOptions<APIList<GetCollaboratorsResponse>, APIError> = {},
  territoryId: StringifiedUUID,
) => {
  const queryClient = useQueryClient();
  const QUERY_KEY = ['collaborators', territoryId];
  const query = useQuery<APIList<GetCollaboratorsResponse>, APIError>(QUERY_KEY, () => getCollaborators(territoryId), {
    ...options,
  });

  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return {
    query,
    invalidateQuery,
  };
};

export const CollaboratorsService = {
  useCreateCollaboratorsMutation,
  useGetCollaborators,
  deleteSharedResources,
};
